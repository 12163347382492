.dot1 {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8f4bf6;
  color: #8f4bf6;
  display: inline-block;
  margin: 0 2px;
}
.dot2 {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8f4bf6;
  color: #8f4bf6;
  display: inline-block;
  margin: 0 2px;
}
.dot3 {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8f4bf6;
  display: inline-block;
  margin: 0 2px;
}
