.selected-chat {
  border-radius: 59px;
  background: #8f4bf6 !important;
  color: #ffffff !important;
}
.radio-checked {
  background-color: #ec694e;
  color: white !important;
  border-color: #ec694e;
  font-weight: bold;
}
