.btn {
  display: flex;
  width: 327px;
  padding: 18px 20px;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-align: center;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
  border-radius: 20px;
}
.plan-des {
  color: #737098;
  text-align: center;
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
