#root
  > div
  > div.css-vc5jm6
  > div.chakra-stack.css-d33mt9
  > div.chakra-stack.css-3oq3if
  > div.chakra-accordion.css-dauwqz
  :where(*, *::before, *::after) {
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
  border-radius: 20px !important;
}
