.from-title {
  color: #000;
  text-align: center;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.24px;
}
.form-desc {
  color: #737098;
  text-align: center;
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.password-desc {
  color: #737098;
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.finish-terms-link {
  color: #737098;
  font-family: Barlow;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.12px;
  cursor: pointer;
}
.finish-terms-link:hover {
  text-decoration: underline !important;
}
.flagWidth {
  width: 544px;
}
@media (min-width: 992px) and (max-width: 1180px) {
  .flagWidth {
    width: 374px !important;
  }
}
.carousel-width {
  width: auto;
}
@media (max-width: 768px) {
  .carousel-width {
    width: 320px !important;
  }
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  color: #8f4bf6;
  background-color: #8f4bf6;
  filter: alpha(opacity=100);
}
.carousel.carousel-slider {
  max-width: 544px !important;
}
@media (max-width: 768px) {
  .carousel.carousel-slider {
    max-width: 320px !important;
  }
}
