.activeLink {
  background-color: white;
  color: black !important;
  border-radius: 56px !important;
}
.activePet {
  background-color: #8f4bf6;
  color: white !important;
  border-radius: 42px !important;
}
.css-1erdjky {
  width: 100%;
  justify-content: space-between;
}
