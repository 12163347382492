body {
  margin: 0;
  font-family: barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: barlow, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  display: block;
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-right: none;
  border-left: none;
  border-radius: 10px;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}

.privacyRequestFormLink
{
  color: blue !important;
}