.css-52dxnr[aria-selected="true"] {
  border: none;
  font-weight: bold;
  color: black;
}
.mailstyle {
  cursor: pointer;
  font-size: 14px;
  color: blue;
  font-weight: 500;
  margin-inline-start: 5px;
}
.mailstyle:hover {
  text-decoration: underline;
}
.xFkwftF6oC7kz2gyXveM ul,
ol {
  padding-left: 0px !important;
}
ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 0px;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
